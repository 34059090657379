import AppRoutes from './router/AppRoutes';
//____ROBOTO FONTS_____
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//_____MUI THEME_____
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
//____Toastify_____
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalState } from './context/GlobalState';
import { useServiceWorker } from './utils/HelperFunctions';
import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';

function App() {
  const globalState = useGlobalState();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    if (waitingWorker && showReload) {
      toast.info(
        <>
          <Typography>Du använder en gammal version. Klicka här för att hämta de senaste ändringarna</Typography>
          <Button
            onClick={() => {
              reloadPage();
            }}
          >
            {'Uppdatera'}
          </Button>
        </>,
        {
          autoClose: false,
          closeOnClick: false,
          style: { alignItems: 'center' }
        }
      );
    }
  }, [reloadPage, showReload, waitingWorker]);

  return (
    <ThemeProvider theme={globalState.muiTheme}>
      <CssBaseline />
      <AppRoutes />
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar
        closeOnClick
        pauseOnHover={false}
        transition={Bounce}
        theme={'colored'}
      />
    </ThemeProvider>
  );
}
export default App;
