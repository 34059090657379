import ReactDOM from 'react-dom/client';
import GlobalState from './context/GlobalState';
import ReactGA from 'react-ga4';
import App from './App';
//____ROBOTO FONTS_____
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const gaTrackingId = 'G-PVQXK158EH';

function Root() {
  return (
    <GlobalState>
      <App />
    </GlobalState>
  );
}

root.render(<Root />);

export const sendToGoogleAnalytics = () => {
  ReactGA.initialize(gaTrackingId);
};
