import React, { useEffect, useRef, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { Database, getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { ArticleTemplateReadView, ScanMode, ThemeInfo, UserReadView } from '../types/types';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth } from 'firebase/auth';
import { ENV_VARIABLES } from '../config';
import { AxiosError } from 'axios';
import { HttpStatusCode } from '../http/http';
import { getSelf, logout } from '../services/Users';
import logoWhite from '../utils/img/brand.png';
import { Theme, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { svSE } from '@mui/x-data-grid';
import { svSE as coreSvSE } from '@mui/material/locale';

type GlobalCtxType = {
  firebaseApp: FirebaseApp;
  firebaseAnalytics: Analytics;
  firebaseDatabase: Database;
  fireStore: Firestore;
  auth: Auth;
  menuMode: ArticleTemplateReadView | undefined;
  setMenuMode: React.Dispatch<React.SetStateAction<ArticleTemplateReadView | undefined>>;
  scanMode: ScanMode | undefined;
  setScanMode: React.Dispatch<React.SetStateAction<ScanMode | undefined>>;
  scannedItems: string[];
  setScannedItems: React.Dispatch<React.SetStateAction<string[]>>;
  scannedItemsRef: React.MutableRefObject<string[]>;
  transformSourceItems: number[];
  setTransformSourceItems: React.Dispatch<React.SetStateAction<number[]>>;
  transformSourceItemsRef: React.MutableRefObject<number[]>;
  scanModeRef: React.MutableRefObject<ScanMode | undefined>;
  menuModeRef: React.MutableRefObject<ArticleTemplateReadView | undefined>;
  handleResponseError: (error: AxiosError) => void;
  handleLogOut: () => void;
  user: UserReadView | null;
  setUser: React.Dispatch<React.SetStateAction<UserReadView | null>>;
  currentTheme: ThemeInfo;
  setTheme: (theme: { primaryColor: string | null; logo: string | null }) => void;
  muiTheme: Theme;
  resetTheme: () => void;
};

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: ENV_VARIABLES.apiKey,
  authDomain: ENV_VARIABLES.authDomain,
  databaseURL: ENV_VARIABLES.databaseURL,
  projectId: ENV_VARIABLES.projectId,
  storageBucket: ENV_VARIABLES.storageBucket,
  messagingSenderId: ENV_VARIABLES.messagingSenderId,
  appId: ENV_VARIABLES.appId,
  measurementId: ENV_VARIABLES.measurementId
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseDatabase = getDatabase(firebaseApp);
const fireStore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const functions = getFunctions(firebaseApp);

const initialTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#000927'
      },
      secondary: {
        main: '#4468E1'
      },
      action: {
        disabledBackground: '#4468E185',
        disabled: grey[400]
      }
    }
  },
  svSE,
  coreSvSE
);

const defaultTheme = {
  primaryColor: '#000927',
  logo: logoWhite
};

const initialContext: GlobalCtxType = {
  firebaseApp,
  firebaseAnalytics,
  firebaseDatabase,
  fireStore,
  auth,
  menuMode: undefined,
  setMenuMode: () => {},
  scanMode: undefined,
  setScanMode: () => {},
  scannedItems: [],
  setScannedItems: () => {},
  scannedItemsRef: { current: [] },
  transformSourceItems: [],
  setTransformSourceItems: () => {},
  transformSourceItemsRef: { current: [] },
  scanModeRef: { current: undefined },
  menuModeRef: { current: undefined },
  handleLogOut: async () => {},
  handleResponseError: async () => {},
  user: null,
  setUser: () => {},
  currentTheme: defaultTheme,
  setTheme: () => {},
  muiTheme: initialTheme,
  resetTheme: () => {}
};

const globalStateCtx = React.createContext<GlobalCtxType>(initialContext);

const GlobalState = (props: { children: any }) => {
  const [menuMode, setMenuMode] = useState<ArticleTemplateReadView>();
  const [scanMode, setScanMode] = useState<ScanMode>();
  const [scannedItems, setScannedItems] = useState<string[]>([]);
  const scannedItemsRef = useRef<string[]>([]);
  const [transformSourceItems, setTransformSourceItems] = useState<number[]>([]);
  const transformSourceItemsRef = useRef<number[]>([]);
  const scanModeRef = useRef<ScanMode>();
  const menuModeRef = useRef<ArticleTemplateReadView>();
  const [user, setUser] = useState<UserReadView | null>(null);
  const [currentTheme, setCurrentTheme] = useState<ThemeInfo>(defaultTheme);
  const [muiTheme, setMUITheme] = useState<Theme>(initialTheme);

  const resetTheme = () => {
    setTheme(defaultTheme);
  };

  const setTheme = (newTheme: { primaryColor: string | null; logo: string | null }) => {
    const theme = { primaryColor: currentTheme.primaryColor, logo: currentTheme.logo };
    var changed = false;
    if (newTheme.primaryColor && theme.primaryColor !== newTheme.primaryColor) {
      theme.primaryColor = newTheme.primaryColor;
      changed = true;
    }
    if (newTheme.logo && theme.logo !== newTheme.logo) {
      theme.logo = newTheme.logo;
      changed = true;
    }
    if (changed) setCurrentTheme(theme);
  };

  useEffect(() => {
    const token = localStorage.getItem('tractech_token');
    const refreshToken = localStorage.getItem('tractech_refresh_token');
    if (!token && !refreshToken) {
      return;
    }
    (async () => {
      const self = await getSelf();
      setUser(self);
    })();
  }, []);

  useEffect(() => {
    setMUITheme(
      createTheme({
        ...initialTheme,
        palette: { ...initialTheme.palette, primary: { main: currentTheme.primaryColor } }
      })
    );
  }, [currentTheme]);

  useEffect(() => {
    scannedItemsRef.current = scannedItems;
  }, [scannedItems]);
  useEffect(() => {
    transformSourceItemsRef.current = transformSourceItems;
  }, [transformSourceItems]);
  useEffect(() => {
    scanModeRef.current = scanMode;
  }, [scanMode]);
  useEffect(() => {
    menuModeRef.current = menuMode;
  }, [menuMode]);

  const handleLogOut = async () => {
    const session = localStorage.getItem('tractech_session_id');
    if (session != null) {
      await logout(session);
    }
    setUser(null);
    localStorage.clear();
    const origin = window.location.origin;
    window.location.replace(origin);
  };

  const handleResponseError = (error: AxiosError) => {
    const { response } = error;

    if (response) {
      // If the auth error is passed to here, the token refreshing was not successful
      // therefore we have to logout
      if (response.status === HttpStatusCode.Unauthorized) {
        setUser(null);
        localStorage.clear();
        const origin = window.location.origin;
        window.location.replace(origin);
      }
    }
  };
  return (
    <globalStateCtx.Provider
      value={{
        firebaseApp,
        firebaseAnalytics,
        firebaseDatabase,
        fireStore,
        auth,
        menuMode,
        setMenuMode,
        scanMode,
        setScanMode,
        scannedItems,
        setScannedItems,
        scannedItemsRef,
        transformSourceItems,
        setTransformSourceItems,
        transformSourceItemsRef,
        scanModeRef,
        menuModeRef,
        handleLogOut,
        handleResponseError,
        user,
        setUser,
        currentTheme,
        setTheme,
        muiTheme,
        resetTheme
      }}
    >
      {props.children}
    </globalStateCtx.Provider>
  );
};

export default GlobalState;
export const useGlobalState = () => React.useContext(globalStateCtx);
