import React, { useEffect, useState } from 'react';

import { useGlobalState } from '../../../context/GlobalState';
import { AxiosError } from 'axios';
import { deleteOrganisation, getOrganisationById, updateOrganisation } from '../../../services/Organisations';
import { toast } from 'react-toastify';
import { OrganisationCreateView, OrganisationReadView } from '../../../types/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Tooltip
} from '@mui/material';
import GenericMap from '../../common/GenericMap';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import NumbersIcon from '@mui/icons-material/Numbers';
import PlaceIcon from '@mui/icons-material/Place';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ComponentLoader from '../../common/componentLoader/ComponentLoader';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import DnDFileInput from '../../common/DnDFileInput';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmail, testPhoneNumber, useWindowSize } from '../../../utils/HelperFunctions';
import { ChromePicker, ColorResult } from 'react-color';
import FreeTextListInput from '../../common/FreeTextListInput';

const EditOrganisation = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const params = useParams<{ orgId: string }>();
  const { isMobileDevice } = useWindowSize();

  const [marker, setMarker] = useState<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
  const [name, setName] = useState<string>('');
  const [identificationNumber, setIdentificationNumber] = useState<string>('');
  const [itemToDelete, setItemToDelete] = useState<OrganisationReadView | null>(null);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editingOrganisation, setEditingOrganisation] = useState<boolean>(false);
  const [orgDisabled, setOrgDisabled] = useState<boolean>(false);
  const [customUiEnabled, setCustomUiEnabled] = useState<boolean>(false);
  const [customUiIcon, setCustomUiIcon] = useState<string | null>(null);
  const [customUiColor, setCustomUiColor] = useState<string>('#FFFFFF');
  const [streetAddress, setStreetAddress] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);
  const [addressLocation, setAddressLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [newAddressInfo, setNewAddressInfo] = useState<string[] | null>(null);
  const [referralLink, setReferralLink] = useState<string | null>(null);
  const [keywords, setKeywords] = useState<string[]>();
  const [contactName, setContactName] = useState<string | null>(null);
  const [contactEmail, setContactEmail] = useState<string | null>(null);
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);
  const [kommun, setKommun] = useState<string | null>(null);
  const [vatNumber, setVatNumber] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  useEffect(() => {
    if (!params.orgId) {
      toast.error('Something went wrong. Please refresh the page!');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        setOrganisation(_organisation);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
  }, [globalState, params.orgId]);

  useEffect(() => {
    if (!organisation) {
      return;
    }
    setName(organisation.name);
    setMarker({ lat: organisation.lat ?? 55, lng: organisation.lng ?? 13 });
    setIdentificationNumber(organisation.identificationNumber);
    setOrgDisabled(organisation.disabled);
    setCustomUiEnabled(organisation.customUiEnabled);
    setCustomUiIcon(organisation.customUiIcon);
    setCustomUiColor(organisation.customUiColor ?? '#FFFFFF');
    setStreetAddress(organisation?.streetAddress);
    setCity(organisation.city);
    setCountry(organisation.country);
    setPostalCode(organisation.postalCode);
    setKommun(organisation.kommun);
    setRegion(organisation.region);
    setReferralLink(organisation.referralLink);
    setKeywords(organisation.keywords);
    setContactName(organisation.contactName);
    setContactEmail(organisation.contactEmail);
    setContactPhoneNumber(organisation.contactPhoneNumber);
    setVatNumber(organisation.vatNumber);
    setDisplayName(organisation.displayName);
  }, [organisation]);

  const isFormValid =
    name !== undefined &&
    identificationNumber !== undefined &&
    name?.trim() !== '' &&
    identificationNumber.trim() !== '';

  const resolveForm = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!organisation) {
      toast.error('Something went wrong!');
      return;
    }
    if (!isFormValid) {
      toast.error('Please check the inputs!');
      return;
    }
    if (marker.lat !== organisation.lat || marker.lng !== organisation.lng) {
      const success = await getMarkerAddress();
      if (!success) {
        editOrganisation();
      }
    } else if (
      streetAddress !== organisation.streetAddress ||
      city !== organisation.city ||
      country !== organisation.country ||
      postalCode !== organisation.postalCode
    ) {
      getAddressLocation();
    } else {
      editOrganisation();
    }
  };

  const formData = (): OrganisationCreateView => {
    return {
      name,
      ...marker,
      identificationNumber,
      disabled: orgDisabled,
      customUiEnabled: customUiEnabled,
      customUiColor: customUiColor,
      customUiIcon: customUiIcon,
      streetAddress: streetAddress,
      city: city,
      country: country,
      postalCode: postalCode,
      kommun: kommun,
      region: region,
      referralLink: referralLink,
      keywords: keywords,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactPhoneNumber,
      vatNumber: vatNumber,
      displayName: displayName
    };
  };

  const editOrganisation = async (data?: OrganisationCreateView) => {
    const _data = data ?? formData();
    setEditingOrganisation(true);
    if (!organisation) {
      toast.error('Something went wrong!');
      return;
    }
    try {
      const _organisation = await updateOrganisation(organisation.id, _data);
      toast.success('Organisationen är uppdaterad');
      setOrganisation(_organisation);
      setAddressLocation(null);
      setNewAddressInfo(null);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setEditingOrganisation(false);
    }
  };

  type Predicate<T> = (t: T) => boolean;

  const requestLocationData = async (
    request: google.maps.GeocoderRequest,
    filter: Predicate<google.maps.GeocoderResult> = () => true
  ) => {
    const geocoder = new google.maps.Geocoder();
    const result = await geocoder.geocode(request);
    return result.results?.find(filter);
  };

  const getAddressLocation = async () => {
    const res = await requestLocationData({
      address: [streetAddress ?? '', city ?? '', country ?? '', postalCode ?? ''].join(' ')
    });
    const location = res?.geometry.location;
    if (location) {
      setAddressLocation({ lat: location.lat(), lng: location.lng() });
    }
  };

  const getMarkerAddress = async () => {
    const res = await requestLocationData(
      {
        location: { lat: marker.lat, lng: marker.lng }
      },
      (result) => result.types.includes('street_address')
    );
    if (!res) {
      toast.error('Det finns ingen adress för vald geoposition. Vänligen fyll i en giltig adress.', {
        autoClose: 10000
      });
      return false;
    }
    const findAddrComponent = (type: string) => {
      return res.address_components.find((component) => component.types.includes(type))?.long_name;
    };

    const route = findAddrComponent('route');
    const streetNumber = findAddrComponent('street_number');
    const _city = findAddrComponent('postal_town') ?? findAddrComponent('locality');
    const _country = findAddrComponent('country');
    const _postalCode = findAddrComponent('postal_code');
    var _streetAddress;
    if (route) {
      _streetAddress = route + (streetNumber ? ' ' + streetNumber : '');
    }
    if (_streetAddress && _city && _country && _postalCode) {
      setNewAddressInfo([res.formatted_address, _streetAddress, _city, _country, _postalCode]);
    } else {
      return false;
    }
    return true;
  };

  const revertChanges = () => {
    if (!organisation) {
      toast.error('Something went wrong, please refresh the page!');
      return;
    }
    setName(organisation.name);
    setMarker({ lat: organisation.lat ?? 55, lng: organisation.lng ?? 13 });
    setIdentificationNumber(organisation.identificationNumber);
    setOrgDisabled(organisation.disabled);
    setCustomUiEnabled(organisation.customUiEnabled);
    setCustomUiIcon(organisation.customUiIcon);
    setCustomUiColor(organisation.customUiColor ?? '#FFFFFF');
    setStreetAddress(organisation?.streetAddress);
    setCity(organisation.city);
    setCountry(organisation.country);
    setPostalCode(organisation.postalCode);
    setKommun(organisation.kommun);
    setRegion(organisation.region);
    setReferralLink(organisation.referralLink);
    setKeywords(organisation.keywords);
    setContactName(organisation.contactName);
    setContactEmail(organisation.contactEmail);
    setContactPhoneNumber(organisation.contactPhoneNumber);
  };

  const removeOrganisation = async () => {
    if (!itemToDelete || !organisation) {
      toast.error('Something went wrong. Try again later.');
      return;
    }
    setLoading(true);
    try {
      const status = await deleteOrganisation(organisation.id);
      if (status) {
        setItemToDelete(null);
        navigate('/admin/organisations', { replace: true });
      }
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const changeHappened =
    organisation &&
    (organisation.name !== name ||
      organisation.identificationNumber !== identificationNumber.trim() ||
      organisation.lat !== marker.lat ||
      organisation.lng !== marker.lng ||
      Number(organisation.disabled) - Number(orgDisabled) === 1 ||
      Number(organisation.disabled) - Number(orgDisabled) === -1 ||
      organisation.customUiColor !== customUiColor ||
      organisation.customUiEnabled !== customUiEnabled ||
      organisation.customUiIcon !== customUiIcon ||
      organisation.streetAddress !== streetAddress ||
      organisation.city !== city ||
      organisation.country !== country ||
      organisation.postalCode !== postalCode ||
      organisation.kommun !== kommun ||
      organisation.region !== region ||
      organisation.referralLink !== referralLink ||
      organisation.keywords !== keywords ||
      organisation.contactName !== contactName ||
      organisation.contactEmail !== contactEmail ||
      organisation.contactPhoneNumber !== contactPhoneNumber ||
      organisation.vatNumber !== vatNumber ||
      organisation.displayName !== displayName);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return !loading && organisation ? (
    <Box sx={{ p: 1.5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant='h6'>Redigera organisationen - {organisation.name}</Typography>
        {!isMobileDevice ? (
          <Button
            sx={{ textTransform: 'none' }}
            endIcon={<DeleteIcon />}
            variant='contained'
            color='error'
            onClick={() => {
              setItemToDelete(organisation!);
            }}
          >
            {'Ta bort'}
          </Button>
        ) : (
          <IconButton
            sx={{ textTransform: 'none' }}
            color='error'
            onClick={() => {
              setItemToDelete(organisation!);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
      <form onSubmit={resolveForm}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{'Organisationsuppgifter'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='organisation-name'>Namn</InputLabel>
                  <OutlinedInput
                    id='organisation-name'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Namn'
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth required>
                  <InputLabel htmlFor='organisation-number'>Organisationsnummer</InputLabel>
                  <OutlinedInput
                    id='organisation-number'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <NumbersIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Organisationsnummer'
                    onChange={(e) => setIdentificationNumber(e.target.value)}
                    value={identificationNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip title='Ange det namn du vill att din organisation ska visas med. Om du lämnar fältet tomt kommer organisationsnamnet att visas.'>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='display-name'>Visningsnamn</InputLabel>
                    <OutlinedInput
                      id='display-name'
                      type='text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton edge='end'>
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Visningsnamn'
                      onChange={(e) => setDisplayName(e.target.value)}
                      value={displayName}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='vat-number'>VAT-nummer</InputLabel>
                  <OutlinedInput
                    id='vat-number'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <NumbersIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='VAT-nummer'
                    onChange={(e) => setVatNumber(e.target.value)}
                    value={vatNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='referral-link'>Referenslänk</InputLabel>
                  <OutlinedInput
                    id='referral-link'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <LinkIcon />
                      </InputAdornment>
                    }
                    label='Referenslänk'
                    onChange={(e) => setReferralLink(e.target.value)}
                    value={referralLink ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FreeTextListInput
                  limit={10}
                  onChange={setKeywords}
                  initialValues={keywords ?? []}
                  label={'Sökord/Produkter'}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Tooltip title='Maxhöjd är 24px' placement='top' open={tooltipOpen} onClose={handleTooltipClose}>
                  <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
                    <DnDFileInput
                      onFileChange={(file) => setCustomUiIcon(file)}
                      text={'Välj logotyp genom att ladda upp en SVG eller PNG-fil'}
                    />
                  </div>
                </Tooltip>
                <Accordion elevation={0} sx={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='custom-color'>Anpassad färg</InputLabel>
                      <OutlinedInput
                        id='custom-color'
                        type='text'
                        endAdornment={<InputAdornment position='end'></InputAdornment>}
                        label='Custom color'
                        value={customUiColor}
                      />
                    </FormControl>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ChromePicker
                      disableAlpha={true}
                      color={customUiColor}
                      onChange={(color: ColorResult) => {
                        setCustomUiColor(color.hex);
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{'Kontaktuppgifter'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetails>
              <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='contactName'>Kontaktnamn</InputLabel>
                    <OutlinedInput
                      id='contactName'
                      type='text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton edge='end'>
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Kontaktnamn'
                      onChange={(e) => setContactName(e.target.value)}
                      value={contactName ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='contactEmail'>E-postadress</InputLabel>
                    <OutlinedInput
                      id='contactEmail'
                      type='text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton edge='end'>
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label='E-postadress'
                      error={contactEmail !== null && contactEmail.trim() !== '' && !isEmail(contactEmail)}
                      onChange={(e) => setContactEmail(e.target.value)}
                      value={contactEmail ?? ''}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='contactPhoneNumber'>Telefonnummer</InputLabel>
                    <OutlinedInput
                      id='contactPhoneNumber'
                      type='text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton edge='end'>
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Telefonnummer'
                      onChange={(e) => setContactPhoneNumber(e.target.value)}
                      error={
                        contactPhoneNumber !== null &&
                        contactPhoneNumber.trim() !== '' &&
                        !testPhoneNumber(contactPhoneNumber)
                      }
                      value={contactPhoneNumber ?? ''}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>{' '}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{'Adressuppgifter'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='street-address'>Gatuadress</InputLabel>
                  <OutlinedInput
                    id='street-address'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Gatuadress'
                    onChange={(e) => setStreetAddress(e.target.value)}
                    value={streetAddress ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='city'>Ort</InputLabel>
                  <OutlinedInput
                    id='city'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Ort'
                    onChange={(e) => setCity(e.target.value)}
                    value={city ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='kommun'>Kommun</InputLabel>
                  <OutlinedInput
                    id='kommun'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Kommun'
                    onChange={(e) => setKommun(e.target.value)}
                    value={kommun ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='region'>Region</InputLabel>
                  <OutlinedInput
                    id='region'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Region'
                    onChange={(e) => setRegion(e.target.value)}
                    value={region ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='postcode'>Postnummer</InputLabel>
                  <OutlinedInput
                    id='postcode'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Postnummer'
                    onChange={(e) => setPostalCode(e.target.value)}
                    value={postalCode ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='country'>Land</InputLabel>
                  <OutlinedInput
                    id='country'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Land'
                    onChange={(e) => setCountry(e.target.value)}
                    value={country ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='geo-position'>Geoposition</InputLabel>
                  <OutlinedInput
                    id='geo-position'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <PlaceIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Geoposition'
                    value={marker ? `${marker.lat.toString()} , ${marker.lng.toString()}` : ''}
                  />
                </FormControl>
                <Typography variant='caption'>Välj geoposition i kartan</Typography>
                <GenericMap
                  mapContainerStyle={{ width: '100%', height: '325px' }}
                  onClick={(e) => {
                    if (e && e.latLng?.lat() && e.latLng?.lng()) {
                      setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }
                  }}
                  marker={marker}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => revertChanges()}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {itemToDelete && (
        <Dialog
          open={itemToDelete !== null}
          onClose={() => setItemToDelete(null)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Ta bort organisationen?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Vill du ta bort organisationen <span style={{ fontWeight: 'bold' }}>{itemToDelete.name}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button color='info' variant='contained' startIcon={<ClearIcon />} onClick={() => setItemToDelete(null)}>
              Avbryt
            </Button>
            <Button color='error' variant='contained' startIcon={<DeleteIcon />} onClick={() => removeOrganisation()}>
              Ta bort
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {addressLocation && (
        <Dialog
          open={addressLocation !== null}
          onClose={() => {
            setAddressLocation(null);
          }}
          aria-labelledby='change-geoposition-title'
          aria-describedby='change-geoposition-description'
        >
          <DialogTitle id='change-geoposition-title'>{'Uppdatera geoposition från address?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='change-geoposition-description'>
              Vill du uppdatera organisationens geoposition till{' '}
              <span style={{ fontWeight: 'bold' }}>{`(${addressLocation.lat}, ${addressLocation.lng})`}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button
              color='info'
              variant='contained'
              startIcon={<SaveIcon />}
              disabled={editingOrganisation}
              onClick={async () => await editOrganisation()}
            >
              UPPDATERA
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<ClearIcon />}
              onClick={async () => {
                await editOrganisation();
              }}
            >
              BEHÅLL NUVARANDE
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {newAddressInfo && (
        <Dialog
          open={newAddressInfo !== null}
          onClose={() => setNewAddressInfo(null)}
          aria-labelledby='change-address-title'
          aria-describedby='change-address-description'
        >
          <DialogTitle id='change-address-title'>{'Uppdatera address från geoposition?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='change-address-description'>
              Vill du uppdatera organisationens adress till{' '}
              <span style={{ fontWeight: 'bold' }}> {newAddressInfo[0]}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button
              color='info'
              variant='contained'
              startIcon={<SaveIcon />}
              disabled={editingOrganisation}
              onClick={async () =>
                await editOrganisation({
                  ...formData(),
                  streetAddress: newAddressInfo[1],
                  city: newAddressInfo[2],
                  country: newAddressInfo[3],
                  postalCode: newAddressInfo[4]
                })
              }
            >
              UPPDATERA
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<ClearIcon />}
              onClick={async () => await editOrganisation()}
            >
              BEHÅLL NUVARANDE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  ) : loading ? (
    <ComponentLoader bgColor='#fff' />
  ) : (
    <Alert severity='error'>Failed loading the organisation content!</Alert>
  );
};

export default EditOrganisation;
