import React, { useCallback, useState } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { ArticleReadView, ExternalLink, OrganisationBasicInfoView } from '../../types/types';
import { Check } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  items: ArticleReadView[];
  organisation: OrganisationBasicInfoView;
  onSelect: (article: ArticleReadView) => void;
  onDeselect?: (article: ArticleReadView) => void;
  externalLinks?: ExternalLink[];
};
const ArticleUrlSelector = (props: Props) => {
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  const toggleSelected = useCallback(
    (id: number) => {
      const toggledArticle = props.items.find((article) => article.id === id);
      if (toggledArticle) {
        if (selectedItemIds.includes(id)) {
          // remove
          setSelectedItemIds(selectedItemIds.filter((selectedId) => selectedId !== id));
          props.onDeselect && props.onDeselect(toggledArticle);
        } else {
          // add
          setSelectedItemIds([...selectedItemIds, id]);
          props.onSelect(toggledArticle);
        }
      }
    },
    [selectedItemIds, setSelectedItemIds, props]
  );

  const goToLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      {props.organisation.customUiEnabled && props.organisation.customUiIcon ? (
        <img
          src={props.organisation.customUiIcon}
          alt={props.organisation.name}
          style={{ maxWidth: '350px', maxHeight: 'auto' }}
        />
      ) : (
        <Typography align={'center'} typography={'h4'} margin={2}>{`${props.organisation.name}`}</Typography>
      )}
      <List sx={{ alignContent: 'center' }}>
        {props.items.map((article, index) => (
          <div key={`${article.id}-${index}`}>
            <Divider variant='middle' />
            <ListItem sx={{ '&:hover': { backgroundColor: 'lightgray' } }} onClick={() => toggleSelected(article.id)}>
              <ArticleListCard article={article} selected={selectedItemIds.includes(article.id)} />
            </ListItem>
          </div>
        ))}
        {props.externalLinks &&
          props.externalLinks.map((link, index) => (
            <div key={`${link.displayText}-${index}`}>
              <Divider variant='middle' />
              <Tooltip title={link.externalUrl}>
                <ListItem
                  sx={{ '&:hover': { backgroundColor: 'lightgray' } }}
                  onClick={() => goToLink(link.externalUrl)}
                >
                  <ExternalLinkListCard {...link} />
                </ListItem>
              </Tooltip>
            </div>
          ))}
      </List>
    </>
  );
};

const ArticleListCard = (props: { article: ArticleReadView; selected: boolean }) => {
  return (
    <>
      <ListItemIcon sx={{ width: 35, height: 35 }}>
        {props.selected ? <Check /> : <img src={props.article.menuIcon} alt='menu-icon' />}
      </ListItemIcon>
      <ListItemText primary={props.article.displayName} />
    </>
  );
};

const ExternalLinkListCard = (link: ExternalLink) => {
  return (
    <>
      <ListItemIcon sx={{ width: 35, height: 35 }}>
        <OpenInNewIcon />
      </ListItemIcon>
      <ListItemText primary={link.displayText} />
    </>
  );
};

export default ArticleUrlSelector;
