import { Box, Button, IconButton, List, ListItem, Paper, Slide, SxProps, Theme, Typography } from '@mui/material';
import { ClassifierReadView } from '../../types/types';
import { useCallback, useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DropdownListSelector from '../common/DropdownListSelector';
import { useHomeContext } from '../../context/HomeContext';

type StateToggle = {
  current: boolean;
  reset: () => void;
};
type Props = {
  toggleStateCallback?: () => void;
  clearState: StateToggle;
  sx?: SxProps<Theme>;
};
const CategorySelector = (props: Props) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);

  const homeContext = useHomeContext();

  const selectSubcategories = useCallback(
    (subs: ClassifierReadView[]) => {
      const params = new URLSearchParams(homeContext.searchParams);

      const subCategories = homeContext.classifiers.filter((cat) => cat.type === 'SUB_CATEGORY').map((cat) => cat.id);
      const mainCats = params.getAll('category').filter((id) => !subCategories.includes(Number(id)));
      const newSubCategoriesList = subs.map((cat) => cat.id);
      params.delete('category');
      [...mainCats, ...newSubCategoriesList].forEach((id) => params.append('category', `${id}`));
      homeContext.setSearchParams(params);
    },
    [homeContext]
  );

  const selectMainCategory = useCallback(
    (id: number) => {
      const params = new URLSearchParams(homeContext.searchParams);

      const mainCategories = homeContext.classifiers.filter((cat) => cat.type === 'CATEGORY').map((cat) => cat.id);
      const subCats = params.getAll('category').filter((id) => !mainCategories.includes(Number(id)));
      const mainCats = params.getAll('category').filter((id) => mainCategories.includes(Number(id)));
      const newMainCategoriesList = mainCats.includes(`${id}`)
        ? mainCats.map((catId) => Number(catId)).filter((catId) => catId !== id)
        : [...mainCats, id];
      params.delete('category');
      [...newMainCategoriesList, ...subCats].forEach((id) => params.append('category', `${id}`));
      homeContext.setSearchParams(params);
    },
    [homeContext]
  );

  useEffect(() => {
    const categoryIds = homeContext.searchParams.getAll('category');
    const mainCategories = homeContext.classifiers.filter((cat) => cat.type === 'CATEGORY').map((cat) => cat.id);
    setSelected(categoryIds.filter((id) => mainCategories.includes(Number(id))).map((id) => Number(id)));
  }, [homeContext.searchParams, homeContext.classifiers]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.visualViewport?.addEventListener('resize', handleResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='categorySelector'>
        <Slide direction='left' in={modalOpen}>
          <Box className={`categorySelectorModal${modalOpen ? '' : ' hidden'}`}>
            <Typography>Underkategorier</Typography>
            <DropdownListSelector
              options={homeContext.classifiers.filter((cat) => cat.type === 'SUB_CATEGORY')}
              display={(cat) => cat.name}
              equalityFunction={(subCat1, subcat2) => subCat1.id === subcat2.id}
              clearState={props.clearState}
              onSelectedChange={selectSubcategories}
              placeholder='Underkategorier'
              selected={homeContext.classifiers.filter((cat) => homeContext.filteredSubCategories.includes(cat.id))}
              sx={{ maxHeight: viewportHeight * 0.3 }}
            />
          </Box>
        </Slide>
        <List sx={{ ...props.sx, overflow: 'auto', verticalAlign: 'center' }}>
          {homeContext.classifiers
            .filter((cat) => cat.type === 'CATEGORY')
            .map((category) => (
              <ListItem key={category.id} alignItems='center'>
                <Paper
                  elevation={selected.includes(category.id) ? 0 : 15}
                  className={`categoryButton${selected.includes(category.id) ? ' selected' : ''}`}
                >
                  <Button onClick={() => selectMainCategory(category.id)} style={{ padding: '5px 0' }}>
                    <img
                      src={`${category.icon}`}
                      alt={category.name}
                      style={{ height: '55px', maxHeight: '5vh', width: 'auto' }}
                    />
                    <img
                      src={`${homeContext.simpleIcons.get(category.id)}`}
                      alt={`${category.name} simple icon`}
                      style={{ position: 'absolute', bottom: 0, left: 0 }}
                    />
                  </Button>
                </Paper>
              </ListItem>
            ))}
          <ListItem key={'expand'} alignItems='center' sx={{ justifyContent: 'center' }}>
            <Paper
              sx={{ width: '100%', justifyContent: 'center' }}
              elevation={modalOpen ? 0 : 15}
              className={`categoryButton${modalOpen ? ' selected' : ''}`}
            >
              <IconButton
                onClick={() => {
                  setModalOpen((prev) => !prev);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
            </Paper>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default CategorySelector;
