import { Box, Dialog, LinearProgress, Typography } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';

import { ArticleGraphView, ArticleReadView, ExternalLink, OrganisationBasicInfoView } from '../../types/types';
import Map from '../map/ArticleMap';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { selectContent } from '../../services/Analytics';
import { getArticleGraphById, getArticleByUrl as getArticlesByUrl } from '../../services/Article';
import ArticleUrlSelector from '../common/ArticleSelector';
import { getBasicOrganisationInfo } from '../../services/Organisations';
import { getUrlByUrl } from '../../services/URLs';

const RestaurantMapScreen = () => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [loading, setLoading] = useState<boolean>(true);
  const [graph, setGraph] = useState<ArticleGraphView>();
  const [selectedArticle, setselectedArticle] = useState<number>();
  const [selecting, setSelecting] = useState<boolean>(false);
  const [possibleArticles, setPossibleArticles] = useState<ArticleReadView[]>();
  const [externalLinks, setExternalLinks] = useState<ExternalLink[]>([]);
  const [organisation, setOrganisation] = useState<OrganisationBasicInfoView>();
  const [hasErred, setHasErred] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const pathname = window.location.pathname;
      const customUrl = await getUrlByUrl(pathname);
      setExternalLinks(customUrl.externalLinks);
      const _articles = (await getArticlesByUrl(pathname)).records;
      const _organisation = await getBasicOrganisationInfo(_articles[0].organisationId);
      setOrganisation(_organisation);
      if (_articles.length + customUrl.externalLinks.length > 1) {
        setSelecting(true);
        setPossibleArticles(_articles);
      } else if (_articles.length === 1) {
        setselectedArticle(_articles[0].id);
      }
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
      setHasErred(true);
    } finally {
      setLoading(false);
    }
  };

  const getGraph = useCallback(
    async (selectedArticle: number | undefined) => {
      if (selectedArticle) {
        try {
          const _graph = await getArticleGraphById(selectedArticle);
          const sourceArticle = _graph.articles.find((a) => a.id === _graph.id);
          if (sourceArticle && sourceArticle.disabled) {
            toast.error('Innehållet i skannad förpackningar har återkallats av leverantör.', { autoClose: false });
          }
          if (sourceArticle && (sourceArticle.customUiColor || sourceArticle.customUiColor)) {
            globalState.setTheme({ primaryColor: sourceArticle.customUiColor, logo: sourceArticle.customIcon });
          }
          setGraph(_graph);
        } catch (e) {
          globalState.handleResponseError(e as AxiosError);
          setHasErred(true);
        } finally {
          setLoading(false);
        }
      }
    },
    [globalState]
  );

  useEffect(() => {
    const handlePopstate = () => {
      setSelecting(true);
    };

    // Listen for the popstate event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    getGraph(selectedArticle);
  }, [selectedArticle, getGraph]);

  useEffect(() => {
    fetchData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectArticle = (article: ArticleReadView) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(window.location);
    setSelecting(false);
    setselectedArticle(article.id);
    selectContent({ content_type: 'article_custom_url', content_id: `${article.id}` });
  };

  return (
    <>
      {loading && <LinearProgress sx={{ width: '100%', height: '.75rem' }} color='secondary' />}
      {(graph || selecting) && !loading && organisation && (
        <>
          <Map loading={loading} data={graph} disableOverLay />
          <Dialog open={selecting && possibleArticles !== undefined}>
            <ArticleUrlSelector
              organisation={organisation}
              items={possibleArticles ?? []}
              externalLinks={externalLinks}
              onSelect={selectArticle}
            />
          </Dialog>
        </>
      )}
      {hasErred && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000927',
            p: 2.5
          }}
        >
          <Typography variant='h4' color={'white'} sx={{ fontWeight: 'bold' }}>
            Förlåt
          </Typography>
          <Typography variant='h5' color={'white'}>
            Den här webbadressen har inga data tilldelade att visa
          </Typography>
        </Box>
      )}
    </>
  );
};
export default RestaurantMapScreen;
