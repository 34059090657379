import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import { grey } from '@mui/material/colors';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import ComponentLoader, { componentLoaderStyles } from './componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';

type Props = {
  mapContainerStyle?: React.CSSProperties;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onZoomChanged?: () => void;
  marker?: google.maps.LatLngLiteral;
};
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];

const GenericMap = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.LatLngLiteral>();

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      let defaultLocation = { lat: 59.2781126, lng: 15.1674353 };
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );

      map.setOptions({
        center: defaultLocation,
        zoom: 14,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID
      });

      if (props.marker) {
        map.setCenter(props.marker);
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position && !markerRef.current) {
              const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
              map.setCenter(currentLocation);
            }
          },
          (error) => console.warn('Browser location error', error)
        );
      }

      setMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    markerRef.current = props.marker;
    if (props.marker !== undefined && map !== null) {
      map.panTo(props.marker);
    }
  }, [map, props.marker]);

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded ? (
    <ComponentLoader />
  ) : (
    <GoogleMap
      id='react-google-maps'
      mapContainerStyle={props.mapContainerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={props.onZoomChanged}
      onClick={props.onClick}
    >
      {props.marker && <Marker position={props.marker} />}
    </GoogleMap>
  );
};
export default GenericMap;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarkerClustererStyles: ClusterIconStyle[] = [
  { height: 70, width: 70, url: require('../../utils/img/m1.png'), textColor: 'white', textSize: 12 },
  { height: 75, width: 75, url: require('../../utils/img/m2.png'), textColor: 'black', textSize: 12 },
  { height: 80, width: 80, url: require('../../utils/img/m3.png'), textColor: 'white', textSize: 13 },
  { height: 85, width: 85, url: require('../../utils/img/m4.png'), textColor: 'black', textSize: 13 },
  { height: 90, width: 90, url: require('../../utils/img/m5.png'), textColor: 'white', textSize: 14 }
];
