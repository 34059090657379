import { Box, Button, Link as MUILink, Typography } from '@mui/material';
import { InfoBox } from '@react-google-maps/api';
import { isMobileDevice } from '../../utils/HelperFunctions';
import { OrganisationSummaryView } from '../../types/types';

type Props = {
  organisation: OrganisationSummaryView;
  onClose: () => void;
};
const OrganisationInfoBox = (props: Props) => {
  const prepareReferralLink = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  };

  return (
    <InfoBox
      options={{
        enableEventPropagation: true,
        closeBoxURL: '',
        boxStyle: {
          boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
          backgroundColor: 'rgba(255,255,255,0)'
        }
      }}
      position={new google.maps.LatLng({ lat: props.organisation.lat, lng: props.organisation.lng })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start ',
          backgroundColor: 'white',
          padding: '1rem .5rem 0',
          borderRadius: '1rem',
          minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%',
          overflowX: 'hidden'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {props.organisation.referralLink ? (
              <MUILink
                href={prepareReferralLink(props.organisation.referralLink)}
                target='_blank'
                color={'secondary'}
                sx={{ fontSize: '14px' }}
                noWrap
                rel='external'
              >
                {props.organisation.name}
              </MUILink>
            ) : (
              <Typography variant='body2' align='left' noWrap sx={{ fontSize: '14px' }}>
                {props.organisation.name}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            {props.organisation.keywords &&
              props.organisation.keywords.map((keyword, index) => (
                <Typography
                  key={`${index}-${keyword}`}
                  variant='body1'
                  align='left'
                  noWrap
                  textOverflow={'ellipsis'}
                  sx={{ fontSize: '12px' }}
                >
                  {keyword}
                </Typography>
              ))}
          </Box>
        </Box>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClose();
          }}
          color={'primary'}
          fullWidth
        >
          Stäng
        </Button>
      </Box>
    </InfoBox>
  );
};
export default OrganisationInfoBox;
