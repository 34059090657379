import {
  ArticleTemplateReadView,
  EPCToText,
  OrganisationReadView,
  OrganisationSummaryView,
  UserReadView
} from '../types/types';
import gris from './img/gris.png';
import gurka from './img/gurka.png';
import ko from './img/ko.png';
import kott from './img/kott.png';
import resturang from './img/resturang.png';
import bulle from './img/bulle.png';
import potatis from './img/potatis.png';
import skaladPotatis from './img/skaladPotatis.png';
import morot from './img/morot.png';
import agg from './img/agg.png';
import aggKartong from './img/aggKartong.png';
import hona from './img/hona.png';
import lok from './img/lok.png';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import * as serviceWorkerRegistration from './../serviceWorkerRegistration';

export const isMobileDevice = (): boolean => window.innerWidth <= 765;
export const retrieveEpc = (str: string): string => {
  let url;
  try {
    url = new URL(str);
  } catch (e) {
    console.log(e);
    return str;
  }
  const searchParams = url.searchParams.get('epc');
  const valid: boolean = url.protocol === 'http:' || url.protocol === 'https:';
  return valid && searchParams ? searchParams : str;
};
export const selectedItemText = (id: string): { text: string; link?: string } => {
  const idCode = id.split('-').pop();
  if (idCode) {
    if ((idCode as string).toLowerCase().endsWith('transformed')) {
      return {
        text: 'Ljuraskolan Norrlöping',
        link: 'https://norrkoping.se/skola-och-forskola/grundskola/kommunala-grundskolor/skolor/ljuraskolan'
      };
    }
    if (/^B1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B1.org, ...(EPCToText.B1.link && { link: EPCToText.B1.link }) };
    }
    if (/^B2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B2.org, ...(EPCToText.B2.link && { link: EPCToText.B2.link }) };
    }
    if (/^B3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B3.org, ...(EPCToText.B3.link && { link: EPCToText.B3.link }) };
    }
    if (/^B4[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B4.org, ...(EPCToText.B4.link && { link: EPCToText.B4.link }) };
    }
    if (/^B5[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.B5.org, ...(EPCToText.B5.link && { link: EPCToText.B5.link }) };
    }
    if (/^C1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C1.org, ...(EPCToText.C1.link && { link: EPCToText.C1.link }) };
    }
    if (/^C2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C2.org, ...(EPCToText.C2.link && { link: EPCToText.C2.link }) };
    }
    if (/^C3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C3.org, ...(EPCToText.C3.link && { link: EPCToText.C3.link }) };
    }
    if (/^C4[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C4.org, ...(EPCToText.C4.link && { link: EPCToText.C4.link }) };
    }
    if (/^C5[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C5.org, ...(EPCToText.C5.link && { link: EPCToText.C5.link }) };
    }
    if (/^C6[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C6.org, ...(EPCToText.C6.link && { link: EPCToText.C6.link }) };
    }
    if (/^C7[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C7.org, ...(EPCToText.C7.link && { link: EPCToText.C7.link }) };
    }
    if (/^C8[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.C8.org, ...(EPCToText.C8.link && { link: EPCToText.C8.link }) };
    }
    if (/^CU[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CU.org, ...(EPCToText.CU.link && { link: EPCToText.CU.link }) };
    }
    if (/^MB[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.MB.org, ...(EPCToText.MB.link && { link: EPCToText.MB.link }) };
    }
    if (/^PI1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI1.org, ...(EPCToText.PI1.link && { link: EPCToText.PI1.link }) };
    }
    if (/^PI2[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI2.org, ...(EPCToText.PI2.link && { link: EPCToText.PI2.link }) };
    }
    if (/^PI3[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PI3.org, ...(EPCToText.PI3.link && { link: EPCToText.PI3.link }) };
    }
    if (/^P1[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.P1.org, ...(EPCToText.P1.link && { link: EPCToText.P1.link }) };
    }
    if (/^PO[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PO.org, ...(EPCToText.PO.link && { link: EPCToText.PO.link }) };
    }
    if (/^PP[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PP.org, ...(EPCToText.PP.link && { link: EPCToText.PP.link }) };
    }
    if (/^CA[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CA.org, ...(EPCToText.CA.link && { link: EPCToText.CA.link }) };
    }
    if (/^CH[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.CH.org, ...(EPCToText.CH.link && { link: EPCToText.CH.link }) };
    }
    if (/^E[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.E.org, ...(EPCToText.E.link && { link: EPCToText.E.link }) };
    }
    if (/^O[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.O.org, ...(EPCToText.O.link && { link: EPCToText.O.link }) };
    }
    if (/^PA[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PA.org, ...(EPCToText.PA.link && { link: EPCToText.PA.link }) };
    }
    if (/^PE[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.PE.org, ...(EPCToText.PE.link && { link: EPCToText.PE.link }) };
    }
    if (/^SL[0-9]*$/g.test(idCode)) {
      return { text: EPCToText.SL.org, ...(EPCToText.SL.link && { link: EPCToText.SL.link }) };
    }
    return { text: id };
  }
  return { text: 'N/A' };
};

export const getMapIcon = (id: string): any => {
  const idCode = id.split('-').pop();
  if (idCode) {
    if ((idCode as string).toLowerCase().endsWith('transformed')) {
      return resturang;
    }
    if (/^B1[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B2[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B3[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B4[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^B5[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^C1[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C2[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C3[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C4[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C5[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C6[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C7[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^C8[0-9]*$/g.test(idCode)) {
      return ko;
    }
    if (/^CU[0-9]*$/g.test(idCode)) {
      return gurka;
    }
    if (/^MB[0-9]*$/g.test(idCode)) {
      return bulle;
    }
    if (/^P1[0-9]*$/g.test(idCode)) {
      return potatis;
    }
    if (/^PI1[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PI2[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PI3[0-9]*$/g.test(idCode)) {
      return gris;
    }
    if (/^PO[0-9]*$/g.test(idCode)) {
      return kott;
    }
    if (/^PP[0-9]*$/g.test(idCode)) {
      return skaladPotatis;
    }
    if (/^CA[0-9]*$/g.test(idCode)) {
      return morot;
    }
    if (/^CH[0-9]*$/g.test(idCode)) {
      return hona;
    }
    if (/^E[0-9]*$/g.test(idCode)) {
      return agg;
    }
    if (/^O[0-9]*$/g.test(idCode)) {
      return lok;
    }
    if (/^PA[0-9]*$/g.test(idCode)) {
      return aggKartong;
    }
    if (/^PE[0-9]*$/g.test(idCode)) {
      return skaladPotatis;
    }
    if (/^SL[0-9]*$/g.test(idCode)) {
      return kott;
    }
  }
  return undefined;
};

export const searchInOrganisations = (arr: OrganisationReadView[], query: string): OrganisationReadView[] => {
  return arr.filter((obj) => obj.name.toLowerCase().includes(query.toLowerCase()));
};

export const searchInOrganisationSummaries = (
  arr: OrganisationSummaryView[],
  query: string
): OrganisationSummaryView[] => {
  if (!query) return arr;
  return arr.filter((obj) => {
    return (
      obj.name.toLowerCase().includes(query.toLowerCase()) ||
      obj.keywords?.filter((keyword) => keyword.toLowerCase().includes(query.toLowerCase())).length > 0
    );
  });
};

export const searchInArticleTemplates = (arr: ArticleTemplateReadView[], query: string): ArticleTemplateReadView[] => {
  const name = arr.filter((obj) => obj.displayName.toLowerCase().includes(query.toLowerCase()));
  // const orgName = arr.filter((obj) => obj.organisationName.toLowerCase().includes(query.toLowerCase()));
  return [...new Set([...name, ...[]])]; // FIXME: concat orgName when organisationName in ReadView fixed
};
export const searchInUsers = (arr: UserReadView[], query: string): UserReadView[] => {
  return arr.filter((obj) => obj.email.toLowerCase().includes(query.toLowerCase()));
};

export const isEmail = (value: string): boolean =>
  value.search(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) >= 0;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  const [screenSize, setScreenSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
      setScreenSize([window.screen.width, window.screen.height]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return { windowSize, screenSize, isMobileDevice: windowSize[0] <= 765 };
};

export const translateErrorMessage = (message: string): string => {
  const msg = message.toLocaleLowerCase();
  if (msg.includes('authorization') && msg.includes('refused')) {
    return 'Ogiltigt användarnamn eller lösenord';
  }
  if (msg.includes('identification') && msg.includes('valid')) {
    return 'Organisationsnumret är ogiltigt';
  }
  if (msg.includes('entity') && msg.includes('not') && msg.includes('found')) {
    return 'Artikeln finns inte';
  }
  return message;
};

export const prepareOrgNameForLink = (str: string) => {
  // Replace European characters with English characters
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/å/g, 'a');
  str = str.replace(/ö/g, 'o');

  // Replace white spaces with dash (-)
  str = str.replace(/\s+/g, '-');

  return str;
};

export const isValidUrl = (str: string): boolean => {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidCustomUrlPath = (str: string) => {
  const hasWhiteSpace = /\s/.test(str);
  const hasCorrectChars = /^[A-Za-z0-9-]+$/.test(str);
  return !hasWhiteSpace && hasCorrectChars;
};

export const downloadTextFile = (filename: string, text: string) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadBlobFile = (filename: string, data: Blob) => {
  const url = URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

interface Coords {
  lat: number;
  lng: number;
}

export const getDistanceBetween = (source: Coords, destination: Coords) => {
  // Haversine formula for distance between coordinates on a sphere https://en.wikipedia.org/wiki/Haversine_formula
  const inRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  var R = 6371; // Radius of the earth in km
  var latDiff = inRadians(source.lat - destination.lat);
  var lngDiff = inRadians(source.lng - destination.lng);
  var angle =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(inRadians(source.lat)) *
      Math.cos(inRadians(destination.lat)) *
      Math.sin(lngDiff / 2) *
      Math.sin(lngDiff / 2);
  var c = 2 * Math.atan2(Math.sqrt(angle), Math.sqrt(1 - angle));
  var d = R * c; // Distance in km
  return d;
};

export const getMiddlePosition = (source: Coords, destination: Coords) => {
  let lat = (source.lat + destination.lat) / 2;
  let lng = (source.lng - destination.lng) / 2;
  if (lng <= 180 && lng >= -180) {
    lng = (source.lng + destination.lng) / 2;
  } else {
    lng = (source.lng + destination.lng + 360) / 2;
  }
  return { lat, lng };
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const isCustomUrl = (url: string) => {
  const regex = /^((https?:\/\/[a-zA-Z0-9.-:]+)?\/map\/).*/;
  return regex.test(url);
};

export const arraysAreEqual = <T>(arr1: T[], arr2: T[], areEqual?: (t1: T, t2: T) => boolean): boolean => {
  if ((arr1 === undefined) !== (arr2 === undefined)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const comps = arr1.filter((item, index) => {
    return areEqual ? !areEqual(item, arr2[index]) : item !== arr2[index];
  });
  return comps.length === 0;
};

export const testPhoneNumber = (phone: string): boolean => {
  return /^[-\s\d()+]+$/.test(phone);
};

//https://medium.com/toplyne-engineering/pwa-update-notifications-in-a-react-application-f5680d51bb2
export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = useState<boolean>(false);

  // called when a service worker updates. this function is a callback to the actual service worker registration onUpdate.
  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }, []);

  // simply put, this tells the service worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  }, [waitingWorker]);

  // register the service worker
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate
    });
  }, [onSWUpdate]);
  return { showReload, waitingWorker, reloadPage };
};
