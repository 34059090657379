import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import {
  ClassifierReadView,
  GroupMembershipReadView,
  GroupSummaryView,
  OrganisationSummaryView
} from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import { isMobileDevice } from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';
import LoaderImg from '../../utils/img/AppTrac.svg';
import { getGroupByShortName, getOrganisationsForGroup } from '../../services/Groups';
import { getOrganisationSummary } from '../../services/Organisations';
import OrganisationInfoBox from '../common/OrganisationInfoBox';
import { toast } from 'react-toastify';
import { getClassifiers } from '../../services/Classifier';
import { useGlobalState } from '../../context/GlobalState';

type Props = {
  data?: OrganisationSummaryView[];
  disableOverLay?: boolean;
  initialSearch?: string;
};

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];
const GroupMapScreen = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({ lat: 59.2781126, lng: 15.1674353 });
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();
  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [showingUserTooltip, setShowingUserTooltip] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<OrganisationSummaryView[]>([]);
  const [groupMemberships, setGroupMemberships] = useState<GroupMembershipReadView[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationSummaryView>();
  const [classifiers, setClassifiers] = useState<Map<number, ClassifierReadView>>(new Map());
  const [group, setGroup] = useState<GroupSummaryView>();
  const [loading, setLoading] = useState<boolean>();

  const globalCtx = useGlobalState();

  useEffect(() => {
    setLoading(true);
    const groupShortName = window.location.pathname.split('/group/')[1];
    fetchData(groupShortName)
      .catch((reason) => {
        toast.error('Ett fel inträffade.');
      })
      .finally(() => setLoading(false));
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: libraries
  });

  const fetchData = async (shortName: string) => {
    const group = await getGroupByShortName(shortName);
    globalCtx.setTheme({ primaryColor: group.themeColor, logo: group.logo });
    const members = await getOrganisationsForGroup(group.id);
    const _organisations = await Promise.all(
      members.map((membership) => getOrganisationSummary(membership.organisationId).catch())
    );
    getClassifiers().then((res) => {
      setClassifiers(new Map(res.records.map((classifier) => [classifier.id, classifier])));
      return;
    });
    setGroup(group);
    setGroupMemberships(members);
    setOrganisations(_organisations);
  };

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );

      map.setOptions({
        zoom: 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setTilt(45);

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    [streetView]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  const getIcon = useCallback(
    (organisation: OrganisationSummaryView) => {
      const membership = groupMemberships.find((membership) => membership.organisationId === organisation.id);
      if (membership?.icon) {
        return membership.icon;
      } else {
        const organisationCategories = organisation.classifierIds.map((id) => classifiers.get(id));
        const topCategory =
          organisationCategories.find((classifier) => classifier?.type === 'SUB_CATEGORY') ?? organisationCategories[0];
        return topCategory?.icon ?? group?.icon;
      }
    },
    [group, groupMemberships, classifiers]
  );

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded || loading ? (
    <ComponentLoader bgColor='#02636e' bgImg={LoaderImg} />
  ) : (
    <GoogleMap
      id='react-google-maps'
      mapContainerClassName={'google-map-container-style'}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={mapCenter}
    >
      {selectedOrganisation && (
        <OrganisationInfoBox organisation={selectedOrganisation} onClose={() => setSelectedOrganisation(undefined)} />
      )}
      {userPosition && (
        <Marker
          position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
          icon={{
            url: markerPerson,
            scaledSize: new google.maps.Size(40, 40)
          }}
          onClick={() => {
            setShowingUserTooltip((prev) => !prev);
          }}
        >
          {showingUserTooltip && (
            <InfoBox
              options={{
                enableEventPropagation: true,
                closeBoxURL: '',
                boxStyle: {
                  boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                  backgroundColor: 'rgba(255,255,255,0)'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  padding: '1.5rem .5rem',
                  borderRadius: '1rem',
                  minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='body2' align='left' noWrap>
                    Du är här
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    aria-label='close button'
                    onClick={() => setSelectedOrganisation(undefined)}
                    sx={{ padding: 0, marginLeft: 2 }}
                  >
                    <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
            </InfoBox>
          )}
        </Marker>
      )}
      {organisations &&
        organisations.map((summary, index) => (
          <Marker
            key={`${summary.id}-${index}`}
            position={{ lat: summary.lat, lng: summary.lng }}
            icon={{
              scaledSize: isStreetView ? new google.maps.Size(65, 75) : new google.maps.Size(45, 55),
              url: getIcon(summary) ?? ''
            }}
            onClick={() => {
              setSelectedOrganisation(summary);
            }}
            options={{ map: map }}
          />
        ))}
    </GoogleMap>
  );
};

export default GroupMapScreen;
