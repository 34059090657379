import { GoogleMap, InfoBox, MarkerF, OverlayView, Polyline, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Link as MUILink, Typography } from '@mui/material';
import MapOverlay from './MapOverlay';
import { ArticleGraphView } from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import markerPersonWhite from '../../utils/img/markerPersonWhite.png';
import { getDistanceBetween, getMiddlePosition, isMobileDevice } from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';

type Props = { loading: boolean; data?: ArticleGraphView; disableOverLay?: boolean };
type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];
const ArticleMap = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [satellite, setSatellite] = useState<boolean>(false);
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();

  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(7);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [showDistances, setShowDistances] = useState<boolean>(true);

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: libraries
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      let defaultLocation = { lat: 59.2781126, lng: 15.1674353 };
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );

      map.setOptions({
        center: defaultLocation,
        zoom: 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setTilt(45);

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    [streetView]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      map.setOptions({ mapTypeId: satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satellite]);

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  useEffect(() => {
    if (map !== null && !props.loading) {
      if (props.data) {
        const selectedArticle = props.data.articles.find((article) => article.id === props.data?.id) ?? null;
        if (selectedArticle) {
          map.setCenter({ lat: selectedArticle.lat, lng: selectedArticle.lng });
        }
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
              map.setCenter(currentLocation);
            }
          },
          (error) => console.warn('Browser location error', error)
        );
      }
    }
  }, [map, props.data, props.loading]);

  useEffect(() => {
    if (props.data) {
      setShowDistances(props.data.articles.length <= 10);
    }
  }, [props.data]);

  const prepareReferralLink = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  };

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded ? (
    <ComponentLoader />
  ) : (
    <GoogleMap
      id='react-google-maps'
      mapContainerClassName={'google-map-container-style'}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={() => {
        map && setZoomLevel(map.getZoom()!);
      }}
    >
      {props.data &&
        props.data.articles.map((article, index) => (
          <MarkerF
            key={`${article.id}-${index}`}
            position={{ lat: article.lat, lng: article.lng }}
            zIndex={props.data && article.id === props.data.id ? 1 : 0} // move selected article to the front
            icon={{
              scaledSize: isStreetView ? new google.maps.Size(65, 75) : new google.maps.Size(45, 55),
              url: article.mapIcon
            }}
            onClick={() => setSelectedItem(article.id)}
            animation={props.data && article.id === props.data.id ? google.maps.Animation.BOUNCE : undefined}
          >
            {selectedItem && selectedItem === article.id && (
              <InfoBox
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: '',
                  boxStyle: {
                    boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                    backgroundColor: 'rgba(255,255,255,0)'
                  }
                }}
              >
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'flex-start ',
                      backgroundColor: 'white',
                      padding: '1.5rem .5rem',
                      borderRadius: '1rem',
                      minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {article.referralLink ? (
                          <MUILink
                            href={prepareReferralLink(article.referralLink)}
                            target='_blank'
                            color={'secondary'}
                            sx={{ fontSize: '14px' }}
                            noWrap
                            rel='external'
                          >
                            {article.organisationName}
                          </MUILink>
                        ) : (
                          <Typography variant='body2' align='left' noWrap sx={{ fontSize: '14px' }}>
                            {article.organisationName}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography variant='body2' align='left' noWrap sx={{ fontSize: '14px' }}>
                          {article.displayName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        aria-label='close button'
                        onClick={() => setSelectedItem(undefined)}
                        sx={{ padding: 0, marginLeft: 2 }}
                      >
                        <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              </InfoBox>
            )}
          </MarkerF>
        ))}
      {userPosition && (
        <MarkerF
          position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
          icon={{
            url: satellite || isStreetView ? markerPersonWhite : markerPerson,
            scaledSize: new google.maps.Size(40, 40)
          }}
          onClick={() => {
            setSelectedItem(-1);
          }}
        >
          {selectedItem && selectedItem === -1 && (
            <InfoBox
              options={{
                enableEventPropagation: true,
                closeBoxURL: '',
                boxStyle: {
                  boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                  backgroundColor: 'rgba(255,255,255,0)'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  padding: '1.5rem .5rem',
                  borderRadius: '1rem',
                  minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='body2' align='left' noWrap>
                    Du är här
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    aria-label='close button'
                    onClick={() => setSelectedItem(undefined)}
                    sx={{ padding: 0, marginLeft: 2 }}
                  >
                    <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
            </InfoBox>
          )}
        </MarkerF>
      )}
      {props.data &&
        props.data.articles.map((article, index) => {
          return article.sourceArticleIds.map((id) => {
            const source = { lat: article.lat, lng: article.lng };
            const destArticle = props.data!.articles.find((a) => a.id === id);
            const destination = destArticle ? { lat: destArticle.lat, lng: destArticle.lng } : null;
            const distance = source && destination ? getDistanceBetween(source, destination) : null;
            return source && destination ? (
              <React.Fragment key={`${article.id}-${id}-${index}`}>
                <Polyline
                  options={{
                    strokeColor: '#4468E1',
                    strokeWeight: 1,
                    icons: [
                      {
                        icon: { path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW },
                        offset: '45%',
                        repeat: zoomLevel > 8 ? '25%' : undefined
                      }
                    ]
                  }}
                  path={[source, destination]}
                />
                {showDistances && (
                  <OverlayView
                    position={getMiddlePosition(source, destination)}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({ x: -10, y: -10 })}
                  >
                    <Box sx={{ bgcolor: 'secondary.main', borderRadius: '1rem', p: 0.5, marginLeft: -1 }}>
                      <Typography variant='caption' color={'white'}>
                        {distance?.toFixed(0)} Km
                      </Typography>
                    </Box>
                  </OverlayView>
                )}
              </React.Fragment>
            ) : null;
          });
        })}
      {props.data &&
        userPosition &&
        props.data.articles
          .filter((article) => article.id === props.data?.id)
          .map((article, index) => {
            const userLatLng = { lat: userPosition.latitude, lng: userPosition.longitude };
            const distance = getDistanceBetween(userLatLng, article);
            return (
              <React.Fragment key='user-distance'>
                <Polyline
                  options={{
                    strokeColor: '#4468E1',
                    strokeOpacity: 0.0,
                    icons: [
                      {
                        icon: {
                          path: google.maps.SymbolPath.CIRCLE,
                          fillOpacity: 1,
                          strokeOpacity: 1,
                          scale: 1.5
                        },
                        offset: '19px',
                        repeat: '20px'
                      }
                    ]
                  }}
                  path={[userLatLng, article]}
                />
                <OverlayView
                  position={getMiddlePosition(userLatLng, article)}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({ x: -10, y: -10 })}
                >
                  <Box sx={{ bgcolor: 'secondary.main', borderRadius: '1rem', p: 0.5, marginLeft: -1 }}>
                    <Typography variant='caption' color={'white'}>
                      {distance?.toFixed(0)} Km
                    </Typography>
                  </Box>
                </OverlayView>
              </React.Fragment>
            );
          })}
      <MapOverlay
        map={map}
        loading={props.loading}
        satellite={satellite}
        setSatellite={setSatellite}
        showDistances={showDistances}
        setShowDistances={setShowDistances}
        disableOverlay={props.disableOverLay}
      />
    </GoogleMap>
  );
};

export default ArticleMap;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarkerClustererStyles: ClusterIconStyle[] = [
  { height: 70, width: 70, url: require('../../utils/img/m1.png'), textColor: 'white', textSize: 12 },
  { height: 75, width: 75, url: require('../../utils/img/m2.png'), textColor: 'black', textSize: 12 },
  { height: 80, width: 80, url: require('../../utils/img/m3.png'), textColor: 'white', textSize: 13 },
  { height: 85, width: 85, url: require('../../utils/img/m4.png'), textColor: 'black', textSize: 13 },
  { height: 90, width: 90, url: require('../../utils/img/m5.png'), textColor: 'white', textSize: 14 }
];
