import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { Button, Box, Tooltip } from '@mui/material';
import { useGlobalState } from '../../context/GlobalState';
import { LoginOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ProfileMenu = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();

  const handleLogout = () => {
    globalState.handleLogOut();
  };
  return (
    <Box>
      {globalState.user ? (
        <>
          <Tooltip title={globalState.user.email}>
            <Button
              variant='contained'
              startIcon={<LogoutOutlined />}
              color='secondary'
              sx={{ margin: 2, width: '15rem' }}
              onClick={handleLogout}
            >
              Logga ut
            </Button>
          </Tooltip>
        </>
      ) : (
        <Button
          variant='contained'
          startIcon={<LoginOutlined />}
          color='secondary'
          sx={{ margin: 2, width: '15rem' }}
          onClick={() => navigate('/login', { replace: true })}
        >
          Logga in
        </Button>
      )}
    </Box>
  );
};

export default ProfileMenu;
