import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, Button, CircularProgress, Fab, FormControlLabel, FormGroup, FormLabel, IconButton } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';
import { CustomNavigateOptions, ScanMode } from '../../types/types';
import Switch from '@mui/material/Switch';
import ClassifierSelector from './ClassifierSelector';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { searchEvent } from '../../services/Analytics';
import { useHomeContext } from '../../context/HomeContext';

type Props = {
  map: google.maps.Map | null;
  satellite: boolean;
  setSatellite: React.Dispatch<React.SetStateAction<boolean>>;
  displayCounter: { num: number; max: number };
};
const HomeScreenOverlay = (props: Props) => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const scanButtonDivRef = useRef<HTMLDivElement>(null);
  const displayControlDivRef = useRef<HTMLDivElement>(null);
  const searchBarDivRef = useRef<HTMLDivElement>(null);
  const categorySelectorDivRef = useRef<HTMLDivElement>(null);
  const [resetCategoryFilters, setResetCategoryFilters] = useState<boolean>(false);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>();

  const homeCtx = useHomeContext();

  useEffect(() => {
    if (props.map) {
      init(props.map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.map]);

  const init = (map: google.maps.Map) => {
    const scanButtonDiv = document.createElement('div');
    scanButtonDiv.id = 'map-scan-button-div';

    const displayControlDiv = document.createElement('div');
    displayControlDiv.id = 'map-display-control-div';

    const searchBarDiv = document.createElement('div');
    searchBarDiv.id = 'map-search-bar-div';

    const categorySelectorDiv = document.createElement('div');
    categorySelectorDiv.id = 'map-category-selector-div';

    const scanButtonControlDiv = scanButtonDivRef.current;
    const toggleButtonControlDiv = displayControlDivRef.current;
    const searchBarControlDiv = searchBarDivRef.current;
    const categorySelectorControlDiv = categorySelectorDivRef.current;

    scanButtonDiv.appendChild(scanButtonControlDiv as Node);
    map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(scanButtonDiv);

    searchBarDiv.appendChild(searchBarControlDiv as Node);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(searchBarDiv);

    categorySelectorDiv.appendChild(categorySelectorControlDiv as Node);
    map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(categorySelectorDiv);

    displayControlDiv.appendChild(toggleButtonControlDiv as Node);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(displayControlDiv);
  };

  useEffect(() => {
    const div = props.map?.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
    if (div) props.map?.controls[google.maps.ControlPosition.RIGHT_CENTER].push(div);
  }, [homeCtx.classifiers, props.map?.controls]);

  const _onFabClick = () => {
    globalState.setScanMode(ScanMode.SINGLE);
    globalState.setMenuMode(undefined);
    navigate('/scanner', { state: { prevPage: '/' }, replace: true } as CustomNavigateOptions);
  };

  const _clearFilters = useCallback(() => {
    homeCtx.setSearchQuery('');
    homeCtx.resetCategoryFilters();
  }, [homeCtx]);

  useEffect(() => {
    clearTimeout(searchTimer);

    if (homeCtx.searchQuery.length > 3) {
      setSearchTimer(
        setTimeout(() => {
          searchEvent(homeCtx.searchQuery);
        }, 2000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeCtx.searchQuery]);

  return (
    <>
      <>
        <div id='map-scan-button' ref={scanButtonDivRef}>
          <Fab
            size='large'
            color='primary'
            aria-label='scan'
            sx={{ bottom: '1.5rem' }}
            onClick={_onFabClick}
            disabled={homeCtx.loading}
          >
            {homeCtx.loading ? (
              <CircularProgress color='secondary' />
            ) : (
              <QrCodeScannerIcon color='inherit' fontSize='large' />
            )}
          </Fab>
        </div>
        <div ref={searchBarDivRef} id='map-search-bar' className='mapSearchBar'>
          <Box className='filterControlsBox'>
            <FormGroup sx={{ maxWidth: '50vw' }}>
              <FormControlLabel
                sx={{ width: '20rem' }}
                control={
                  <>
                    <input
                      type='text'
                      style={{ fontSize: '16pt', maxWidth: '40vw' }}
                      placeholder='Sök...'
                      className='searchField'
                      onChange={(e) => {
                        if (!homeCtx.loading) homeCtx.setSearchQuery(e.target.value);
                      }}
                      value={homeCtx.searchQuery}
                    />
                    {homeCtx.searchQuery ? (
                      <IconButton
                        aria-label='clear'
                        onClick={() => {
                          homeCtx.setSearchQuery('');
                          searchBarDivRef.current && searchBarDivRef.current.blur();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </>
                }
                label={<></>}
              />
              {props.displayCounter && (
                <FormLabel>{`${props.displayCounter.num} av ${props.displayCounter.max} visas`}</FormLabel>
              )}
              <Button onClick={_clearFilters} sx={{ width: '100%', alignContent: 'center' }}>
                Rensa filter
              </Button>
            </FormGroup>
          </Box>
        </div>
      </>

      <div ref={categorySelectorDivRef} id='map-category-selector'>
        <ClassifierSelector
          sx={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            marginLeft: '1.5rem',
            borderRadius: '1rem'
          }}
          clearState={{
            current: resetCategoryFilters,
            reset: () => {
              setResetCategoryFilters(false);
            }
          }}
        />
      </div>
      <div id='map-display-control' ref={displayControlDivRef}>
        <Box
          sx={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            marginTop: '1rem',
            marginLeft: '1.5rem',
            paddingLeft: '1rem',
            borderRadius: '1rem'
          }}
        >
          <FormGroup sx={{}}>
            <FormControlLabel
              control={
                <Switch size='medium' checked={props.satellite} onChange={() => props.setSatellite(!props.satellite)} />
              }
              label={props.satellite ? 'Satellite' : 'Map'}
            />
          </FormGroup>
        </Box>
      </div>
    </>
  );
};

export default HomeScreenOverlay;
